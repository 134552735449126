import config from '@cfg';
import log from '@util/logging';
import {PageSection} from '@data/types';
import MappedComponentContext from './MappedComponentContext';

// mapping functions
import {_map as mapProseBlock} from '@components/ProseBlock/';
import mapOutcomesCarouselBlock from '@components/OutcomesCarousel/_map';
import mapArticleCarouselBlock from '@components/ArticleCarousel/_map';
import mapCardCarouselBlock from '@components/CardCarousel/_map';
import mapLinkMenuBlock from '@components/LinkMenu/_map';
import mapHeroBlock from '@components/Hero/_map';

import mapCalloutBandBlock from '@components/CalloutBandComponent/_map';
import mapFlexCollarBlock from '@components/FlexCollarComponent/_map';
import mapTextAndImageBlock from '@components/TextAndImageBlock/_map';
import mapShortTextAndImageBlock from '@components/ShortTextAndImageBlock/_map';
import mapDrawerCombo from '@components/DrawerCombo/_maps';
import mapLocationDrawerCombo from '@components/LocationDrawerCombo/_maps';
import mapInteriorHeroBlock from '@components/InteriorHero/_map';
import mapTextTestimonialCardBlock from '@components/TextTestimonialCardComponent/_map';
import {_map as mapGenericEmbedBlock} from '@components/GenericEmbed';
import mapBigPictureCtaBlock from '@components/BigPictureCta/_map';
// section types
import CalloutBandBlockData from '@data/blocks/CalloutBandBlockData';
import OutcomesCarouselBlockData from '@data/blocks/OutcomesCarouselBlockData';
import ArticleCarouselBlockData from '@data/blocks/ArticleCarouselBlockData';
import CardCarouselBlockData from '@data/blocks/CardCarouselBlockData';
import HeroBlockData from '@data/blocks/HomepageHeroData';
import LinkMenuBlockData from '@data/blocks/LinkMenuBlockData';
import ProseBlockData from '@data/blocks/ProseBlockData';
import TextAndImageBlockData from '@data/blocks/TextAndImageBlockData';
import ShortTextAndImageBlockData from '@data/blocks/ShortTextAndImageBlockData';

import FlexCollarBlockData from '@data/blocks/FlexCollarBlockData';
import DrawerComboBlockData from '@data/blocks/DrawerComboBlockData';
import LocationDrawerComboBlockData from '@data/blocks/LocationDrawerComboBlockData';
import InteriorHeroBlockData from '@data/blocks/InteriorHeroData';
import TextTestimonialCardBlockData from '@data/blocks/TextTestimonialCardBlockData';
import GenericEmbedBlockData from '@data/blocks/GenericEmbedBlockData';
import BigPictureCtaBlockData from '@data/blocks/BigPictureCtaBlockData';

export interface sectionBlockPartial extends Record<string, any> {
  _key: string;
}

const MapComponents = ({blocks}: {blocks: sectionBlockPartial[]}) => {
  if (!blocks?.length) {
    if (config.dev) {
      log.error('invalid or empty blocks passed to <MapComponents>', {
        blocks,
      });
    }
    return null;
  }
  const components = blocks.map((block, idx) => {
    const mappedComponent = (() => {
      if (block._type === 'outcomesCarousel') {
        return mapOutcomesCarouselBlock(block as OutcomesCarouselBlockData);
      }
      if (block._type === 'articleCarousel') {
        return mapArticleCarouselBlock(block as ArticleCarouselBlockData);
      }
      if (block._type === 'cardCarousel') {
        return mapCardCarouselBlock(block as CardCarouselBlockData);
      }
      // if (block._type === 'introBlock') {
      //   return mapIntroBlock(block as IntroBlockData);
      // }
      if (block._type === 'calloutBand') {
        return mapCalloutBandBlock(block as CalloutBandBlockData);
      }
      if (block._type === 'placeholder') {
        return (
          <div key={block._key}>
            {(block as sectionBlockPartial & {text: string}).text}
          </div>
        );
      }
      if (block._type === 'prose') {
        return mapProseBlock(block as ProseBlockData);
      }
      if (block._type === 'heroBlock') {
        return mapHeroBlock(block as HeroBlockData);
      }
      if (block._type === 'textAndImageBlock') {
        return mapTextAndImageBlock(block as TextAndImageBlockData);
      }
      if (block._type === 'shortTextAndImageBlock') {
        return mapShortTextAndImageBlock(block as ShortTextAndImageBlockData);
      }
      if (block._type === 'linkMenu') {
        return mapLinkMenuBlock(block as LinkMenuBlockData);
      }
      if (block._type === 'drawerCombo') {
        return mapDrawerCombo(block as DrawerComboBlockData);
      }
      if (block._type === 'locationDrawerCombo') {
        return mapLocationDrawerCombo(block as LocationDrawerComboBlockData);
      }
      if (block._type === 'flexCollar') {
        return mapFlexCollarBlock(block as FlexCollarBlockData);
      }
      if (block._type === 'interiorHero') {
        return mapInteriorHeroBlock(block as InteriorHeroBlockData);
      }
      if (block._type === 'textTestimonialCard') {
        return mapTextTestimonialCardBlock(
          block as TextTestimonialCardBlockData,
        );
      }
      if (block._type === 'bigPictureCta') {
        return mapBigPictureCtaBlock(block as BigPictureCtaBlockData);
      }
      if (block._type === 'genericEmbed') {
        return mapGenericEmbedBlock(block as GenericEmbedBlockData, idx);
      }
      return (
        <div key={block._key || `unknown-${idx}`}>{`unknown block type: ${
          (block as PageSection)._type
        }`}</div>
      );
    })();
    return (
      <MappedComponentContext.Provider
        value={block}
        key={`mapped-section-${idx}`}
      >
        {mappedComponent}
      </MappedComponentContext.Provider>
    );
  });
  return <>{components}</>;
};

export default MapComponents;
