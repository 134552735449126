import styled from 'styled-components';
import {RightArrowNoLine} from '../Arrow/index';
import {TestimonialCard} from '@components/Card';
import Link from '@components/Link';
import {query} from '../../themes/fn';
import { color, font } from '@design/templateFns';
import Block from '@components/Layout/Block';

export interface TextTestimonialCardProps {
  header: string;
  description: string;
  cta: string;
  ctaUrl: string;
  testimonialText: string;
  patientName: string;
  patientNameSecondLine: string;
  patientPhotoPath?: string;
  boxCtaLink: string;
  boxCtaText: string;
  backgroundColor?: string;
}

export const TextTestimonialCard = ({
  header = `People We Treat`,
  description = `At Riggs, we welcome adults (aged 18 and up) from across the country and around the world. People from all walks of life who have stumbled on their walk, and seek a self-directed, behavioral treatment approach to help them return to a more productive, more satisfying life.`,
  cta = `Learn More`,
  ctaUrl = `google.com`,
  testimonialText = `I look at Riggs as the place that broke my fall. In giving me the space to discover my competency, I found my voice. I started by expressing my anger about almost everything. But this expression opened many doors for me. From there, I broke the downward spiral.`,
  patientName = `CJ, former patient`,
  patientPhotoPath,
  patientNameSecondLine,
  boxCtaLink,
  boxCtaText,
  backgroundColor,
}: TextTestimonialCardProps): JSX.Element => {
  return (
    <Block background="bg" narrow>
      <StyledContentContainer>
        <StyledDescriptionContainer>
          <StyledHeadline>{header}</StyledHeadline>
          <StyledParagraph>{description}</StyledParagraph>
          {ctaUrl && cta && (
            <Link href={ctaUrl}>
              <StyledSpan >{cta}</StyledSpan>
              <RightArrowNoLine stroke={'#01617A'} size={8} />
           </Link>           
          )}
        </StyledDescriptionContainer>
        <StyledTestimonial>
          <TestimonialCard
            backgroundColor={backgroundColor}
            patientPhotoPath={patientPhotoPath}
            testimonialText={testimonialText}
            patientName={patientName}
            patientSecondLine={patientNameSecondLine}
            cardFullWidth={true}
            ctaLink={boxCtaLink}
            ctaText={boxCtaText}
          />
        </StyledTestimonial>
      </StyledContentContainer>
    </Block>
  );
};

const StyledSpan = styled.span`
  ${font('headline-xs')};
  color: ${color('cta')};
  margin-right: 9px;
  position: relative;
  bottom: 1px;
`;


const StyledContentContainer = styled.div`
  display: grid;
  max-width: 1064px;
  margin: auto;
  grid-template-columns: 100%;
  @media (${query.atLeast('tablet')}) {
    justify-content: flex-end;
    grid-template-columns: 50% 50%;
  }
  @media (${query.atLeast('max')}) {
    justify-content: flex-end;
    grid-template-columns: 60% 40%;
  }
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  justify-self: center;
  padding-bottom: 25px;
  padding-right: 60px;

  @media (${query.atLeast('tablet')}) {
    justify-content: center;
    padding-bottom: 0px;
    padding-right: 132px;
  }
`;

const StyledTestimonial = styled.div`
  display: flex;
  justify-content: center;

  @media (${query.atLeast('tablet')}) {
    justify-content: flex-end;
  }
`;

const StyledHeadline = styled.h2`
  ${font('headline-xl')};
  font-size: 36px;
  color: ${color('primary')};
  padding: 0;
  margin: 28px 0;

  @media (${query.atLeast('tablet')}) {
    margin: 28px 0;
  }

  @media (${query.atLeast('desktop')}) {
    font-size: 44px;
  }
`;

const StyledParagraph = styled.p`

  ${font('body-lg-medium')};
  padding-bottom: 16px;
  color: ${color('fg')};

  @media (${query.atLeast('tablet')}) {
    padding-bottom: 31px;
  }
`;
