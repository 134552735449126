import {ResolvedImageData} from '@data/types';
import NextImage, {ImageProps} from 'next/image';
import {publicClient as client} from '@data/sanityClient'; // TODO: preview?
import {useNextSanityImage} from 'next-sanity-image';
import {mq} from '@theme/query';
import styled from 'styled-components';

const DesktopImage = styled(NextImage)`
  ${mq({
    display: ['none !important', null, 'block !important'],
  })}
` as typeof NextImage & Record<string, any>;

const MobileImage = styled(NextImage)`
  ${mq({
    display: ['block !important', null, 'none !important'],
  })}
` as React.FC<ImageProps>;

const Wrapper = styled.div`
  position: relative;
`;

const LinkMenuAccentImage = ({
  desktop,
  mobile,
}: {
  desktop?: ResolvedImageData;
  mobile?: ResolvedImageData;
}) => {
  if (!desktop && !mobile) {
    return null;
  }

  const desktopProps = useNextSanityImage(client, desktop || mobile);
  const mobileProps = useNextSanityImage(client, mobile || desktop);

  return (
    <Wrapper>
      <DesktopImage
        src={desktopProps.src}
        loader={desktopProps.loader}
        layout="fill"
        objectFit="cover"
        sizes="50vw"
      />
      <MobileImage
        src={mobileProps.src}
        loader={mobileProps.loader}
        layout="fill"
        objectFit="cover"
      />
    </Wrapper>
  );
};

export default LinkMenuAccentImage;