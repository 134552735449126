import styled from 'styled-components';
import {space, query} from '@theme/fn';
import {color, font} from '@design/templateFns';
import AspectBox from '@components/Layout/AspectBox';
import {mq} from '@theme/query';

export const GridContainer = styled.div`
  /* background: ${color('panel')}; */
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0;
  grid-column-gap: 0;

  @media screen and (${query.atLeast('desktop')}) {
    grid-template-columns: 50% 50%;
  }
`;

export const ContentContainer = styled.div`
  padding: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-row: 2;
  background: ${color('panel')};
  @media screen and (${query.atLeast('tablet')}) {
    margin-top: 0;
    grid-row: ${({reverse}) => (reverse ? 1 : 'unset')};
  }
`;

export const Header = styled.h1`
  ${font('headline-sm')};
  margin: 0 0 16px;
  color: ${color('primary')};
  padding: 0;
`;

export const Text = styled.div`
  color: ${color('fg')};
  p {
    ${font('body-sm-regular')};
  }
`;

export const StyledSpan = styled.span`
  ${font('headline-xxs')};
  color: ${color('cta')};
  margin-right: 9px;
  position: relative;
  bottom: 1px;
  cursor: pointer;
`;

export const ImageBox = styled(AspectBox).attrs({ratio: [1.66]})`
  display: block;
  @media screen and (${query.atLeast('desktop')}) {
    height: auto;
  }
`;
