import {ArticleCard, ArticleCardProps} from '../Card';
import Carousel from '@components/Carousel';
import {bigFetch} from '@data/sanityClient';
import {RenderBasicText} from '@components/PortableText';
import React from 'react';
import {useEffect} from 'react';
import groq from 'groq';
import {useState} from 'react';

export interface ArticleCarouselProps {
  title: string;
  cta_text: string;
  cta_link: string;
  cards: ArticleCardProps[];
  categories: string[];
  _key: string;
}

export const mapArticle = function (article) {
  switch (article._type) {
    case 'news':
      return {
        image: article.image,
        headline: article.headline,
        date: article.date,
        category: article.postType?.title || 'News',
        description: article.description,
        url: article.slug ? `/news/${article.slug.current}` : '#',
      } as ArticleCardProps;
    case 'resource':
      return {
        image: article.image,
        headline: article.headline,
        date: article.date,
        category: article.postType?.title || 'Resource',
        description: article.description,
        url: article.slug
          ? `/education-research/resources/${article.slug.current}`
          : '#',
        cardFullWidth: false,
      } as ArticleCardProps;
    case 'event':
      return {
        image: article.image,
        headline: article.headline,
        date: article.date,
        category: article.postType?.name || 'Event',
        description: <RenderBasicText content={article.description} />,
        url: article.slug ? `/events/${article.slug.current}` : '#',
      } as ArticleCardProps;
    default:
      return {} as ArticleCardProps;
  }
};

export const ArticleCarousel = ({
  title,
  cta_text,
  cta_link,
  cards,
  categories,
}: ArticleCarouselProps): JSX.Element => {
  const [categoryArticles, setCategoryArticles] = useState([]);
  const [combinedArticles, setCombinedArticles] = useState(cards);

  useEffect(() => {
    if (categories)
      Promise.all(
        categories.map((category) => {
          const query = groq`*[(_type== 'event' || _type== 'news' || _type== 'resource' || _type== 'course') && "${category}" in categories[]._ref][0...12] {
          _type,
          slug,
          'image': coalesce(image, mainImage),
          'date': coalesce(publishedAt, eventStart),
          'headline': coalesce(title, name),
          'description': shortDescription,
          'postType': coalesce(postType, resourceType, eventCategory) 
        }`;
          return bigFetch(query);
        }),
      ).then((values) => {
        setCategoryArticles(values);
      });
  }, [categories, setCategoryArticles]);

  useEffect(() => {
    if (categoryArticles.length > 0) {
      let mappedArticles = categoryArticles
        .reduce((arr, curr) => arr.concat(curr)) //combine all the category arrays into one
        .filter((val, i, curr) => curr.indexOf(val) === i) // remove duplicate articles
        .sort(function (a, b) {
          if (new Date(a.date) < new Date(b.date)) return -1;
          if (new Date(a.date) > new Date(b.date)) return 1;
          return 0;
        }) // sort by date
        .slice(0, 12 - cards.length) // only grab enough to fill a carousel with 12 cards max
        .map((article) => mapArticle(article)); // map to Component prop type

      setCombinedArticles(cards.concat(mappedArticles));
    }
  }, [categoryArticles]);

  const cardElements = combinedArticles.map(function (card) {
    return <ArticleCard {...card} />;
  });

  // Hide carousel if no cards are provided
  if (cardElements.length > 0) {
    return (
      <Carousel
        title={title}
        cta={cta_link}
        cta_text={cta_text}
        cards={cardElements}
        background="cream"
      />
    );
  } else {
    return null;
  }
};
