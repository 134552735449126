import Block from '@components/Layout/Block';
import {RightArrowNoLine} from '../Arrow/index';
import Link from '@components/Link';
import type {BasicText, ResolvedImageData} from '@data/types';
import {RenderBasicText} from '@components/PortableText';
import {
  ContentContainer,
  GridContainer,
  Header,
  ImageBox,
  StyledSpan,
  Text,
} from './styles';
import ArcImg from '@components/ArcImg';

export interface ShortTextAndImageBlockProps {
  header?: string;
  img: ResolvedImageData;
  alt_text?: string;
  bodyText: BasicText | string;
  ctaText?: string;
  ctaUrl?: string;
  reverse?: boolean;
}

/*** STYLING ***/

const ShortTextAndImageBlock = ({
  header,
  img,
  alt_text,
  bodyText,
  ctaText,
  ctaUrl,
  reverse,
}: ShortTextAndImageBlockProps): JSX.Element => {
  return (
    <Block narrow>
      <GridContainer reverse={reverse}>
        <ImageBox>
          <ArcImg
            alt={alt_text}
            imageData={img}
            sizes={['50vw', null, null, '635px']}
          />
        </ImageBox>
        <ContentContainer reverse={reverse}>
          {header && <Header>{header}</Header>}
          {typeof bodyText === 'object' ? (
            <Text>
              <RenderBasicText asFragment content={bodyText} />
            </Text>
          ) : (
            <Text>{bodyText}</Text>
          )}
          {ctaUrl && ctaText && (
            <Link href={ctaUrl}>
              <div>
                <StyledSpan>{ctaText}</StyledSpan>
                <RightArrowNoLine stroke={'#01617A'} size={7} />
              </div>
            </Link>
          )}
        </ContentContainer>
      </GridContainer>
    </Block>
  );
};

export default ShortTextAndImageBlock;
