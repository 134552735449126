import styled from 'styled-components';
import {query} from '@theme/fn';
import {color, font} from '@design/templateFns';
import {mq} from '@theme/query';
import {HeroCardData} from '@data/blocks/HomepageHeroData';
import {ResolvedImageData} from '@data/types';
import ArcImg from '@components/ArcImg';
import {mapLink} from '@util/mapping/index';
import {maxWidthPaddingTmplFn} from '@components/Layout/Block';

export interface HomepageHeroProps {
  image: ResolvedImageData;
  title: string;
  heroCards: HeroCardData[];
  alt_text?: string;
  videoSrc?: string;
}

const Chevron = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13L7 7L1 1"
        stroke="#ffbb38"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ChevronDiv = styled.div`
  height: 12px;
  width: 7px;
  margin-left: 5px;
`;

const DesktopChevronDiv = styled(ChevronDiv)`
  display: inline-block;

  @media screen and (${query.atLeast('tablet')}) {
    path {
      stroke: #1b76b0;
    }
  }
`;

const HeroContainer = styled.div`
  width: 100%;
  background: #000;
  position: relative;
  box-sizing: border-box;
  padding-top: 140px;
  padding-bottom: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (${query.atLeast('tablet')}) {
    height: 420px;
    justify-content: flex-end;
  }

  @media screen and (${query.atLeast('desktop')}) {
    height: 682px;
    flex-direction: row;
    align-items: flex-end;
    padding: 80px ${maxWidthPaddingTmplFn()};
    padding-top: 140px;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  min-height: 295px;

  @media screen and (${query.atLeast('tablet')}) {
    height: 420px;
  }

  @media screen and (${query.atLeast('desktop')}) {
    height: 682px;
  }

  img {
    object-position: left bottom;
  }
`;

const ImageGradient = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

  @media screen and (${query.atLeast('desktop')}) {
    background: linear-gradient(
      44.64deg,
      rgba(0, 0, 0, 0.8) 6.68%,
      rgba(0, 0, 0, 0) 54.37%
    );
  }
`;

const Title = styled.div`
  color: #fff;
  ${font('headline-xl')}
  margin: 22px 22px;
  z-index: 0;
  max-width: 70%;

  @media screen and (${query.atLeast('tablet')}) {
    margin: 22px 48px;
  }

  @media screen and (${query.atLeast('desktop')}) {
    font-size: 64px;
    max-width: 1043px;
    line-height: 66.56px;
    margin: 0;
  }
`;

const HeroCardList = styled.div`
  display: flex;
  font-family: ${(props) => props.theme.fonts.body};
  font-weight: 700;
  z-index: 0;
  flex-direction: column;

  @media screen and (${query.atLeast('tablet')}) {
    flex-direction: row;
    margin-left: 48px;
  }

  @media screen and (${query.atLeast('desktop')}) {
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 361px;
    margin: 0;
  }
`;
const SingleHeroCard = styled.div`
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #fff;
  margin: 12px 22px;
  box-sizing: border-box;

  @media screen and (${query.atLeast('tablet')}) {
    margin: 0 12px 0 0;
  }

  @media screen and (${query.atLeast('desktop')}) {
    background: #f7f3e8;
    color: #282828;
    padding: 18.5px 23px;
    margin: 0 0 20px 0;
    min-height: 106px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

const Eyebrow = styled.a`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
  ${font('eyebrow-sm')}
  text-decoration: none;
  margin-bottom: 10px;
  :visited {
    color: #fff;
  }

  @media screen and (${query.atLeast('desktop')}) {
    font-size: 12px;
    color: ${color('cta')};
    :visited {
      color: ${color('cta')};
    }
  }
`;

const Copy = styled.a`
  display: block;
  text-decoration: none;
  color: #fff;
  ${font('headline-xxs')}

  @media screen and (${query.atLeast('desktop')}) {
    color: #282828;
  }

  :visited {
    color: inherit;
  }
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center;

  ${mq({
    display: ['none', 'block'],
  })}
`;

export const HomepageHero = ({
  image,
  title,
  heroCards,
  alt_text,
  videoSrc,
}: HomepageHeroProps): JSX.Element => {
  return (
    <HeroContainer>
      <ImageContainer alt={alt_text}>
        <ArcImg imageData={image} sizes="100vw" />
        {videoSrc && (
          <Video
            {...{
              muted: true,
              src: videoSrc,
              autoPlay: true,
              loop: true,
              playsinline: true,
              key: 'homepage-ambient-video',
              id: 'homepage-ambient-video',
            }}
          />
        )}
        <ImageGradient />
      </ImageContainer>
      <Title>{title}</Title>
      <HeroCardList>
        {heroCards &&
          heroCards.map((card) => (
            <SingleHeroCard key={card._key}>
              <Eyebrow href={mapLink(card.card_link)}>
                {card.card_eyebrow}
              </Eyebrow>
              <Copy href={mapLink(card.card_link)}>
                {card.card_copy}
                <DesktopChevronDiv>
                  <Chevron />
                </DesktopChevronDiv>
              </Copy>
            </SingleHeroCard>
          ))}
      </HeroCardList>
    </HeroContainer>
  );
};
