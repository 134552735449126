import {CarouselCard} from '../Card';
import StaticCarousel from '@components/StaticCarousel';
import React from 'react';

export interface CardCarouselProps {
  title: string;
  cta: string;
  cta_text: string; // this is the component variant, either ivory or blue
  swatch?: string;
  cards: any[];
}

export const CardCarousel = ({
  title,
  cta,
  cta_text,
  swatch,
  cards,
}: CardCarouselProps): JSX.Element => {
  const cardElements = cards.map(function (card, index) {
    return <CarouselCard {...card} />;
  });
  return (
    <StaticCarousel
      title={title}
      cta={cta}
      cta_text={cta_text}
      cards={cardElements}
      background={swatch}
      bleedRight={false}
    />
  );
};
