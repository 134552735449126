import styled from 'styled-components';
import {ReactNode} from 'react';
import React from 'react';
import {query} from '@theme/fn';
import {color, font} from '@design/templateFns';
import {Button} from '../Button/index';
import {CircleArrow} from '@components/Arrow';
import Block from '@components/Layout/Block';
export interface CtaProps {
  url: string;
  label: string;
}
export interface DrawerProps {
  icon?: string;
  title: string;
  content: ReactNode;
}

export interface DrawerComboProps {
  title: string;
  copy?: string;
  cta: CtaProps;
  drawers: DrawerProps[];
}

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (${query.atLeast('tablet')}) {
    flex-direction: row;
  }
`;

const ComboCTA = styled.div`
  margin-bottom: 40px;

  @media screen and (${query.atLeast('tablet')}) {
    margin-left: 40px;
  }
`;

const ComboTextTitle = styled.div`
  ${font('headline-xxl')};
  color: ${color('fg')};
  margin-bottom: 28px;
`;

const ComboTextCopy = styled.div`
  ${font('body-lg-regular')}
  font-size: 22px;
  color: ${color('fg')};
  margin-bottom: 50px;
  @media screen and (${query.atLeast('desktop')}) {
    width: 65%;
  }
`;

const Drawers = styled.div`
  overflow: visible;
`;

const DrawerContainer = styled.div`
  background: ${color('bg')};
  box-shadow: 0px 10px 25px 0px ${color('shadow')};
  border-radius: 4px;
  padding: 25px 40px;
  margin: 0 0 20px 0;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
`;

const DrawerIcon = styled.div`
  width: 58px;
  min-width: 58px;
  height: 58px;
  background-image: url(${(props) => props.backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 20px;
`;
const DrawerHint = styled.div`
  ${font('headline-sm')}
  color: ${color('primary')};
  text-align: left;
  margin-right: auto;
`;

const DrawerToggle = styled.div`
  cursor: pointer;
  margin-left: 20px;
  width: 26px;
  height: 26px;
  transform: ${(props) => (props.pointUp ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transition: transform 0.5s;
`;

const DrawerDetail = styled.div`
  max-height: ${(props) => (props.isOpen ? 'auto' : '0')};
  line-height: ${(props) => (props.isOpen ? '134%' : '0%')};
  opacity: ${(props) => (props.isOpen ? '1.0' : '0.0')};
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  ${font('headline-xxs')}
`;

const DrawerInsert = styled.div`
  padding-top: 10px;
  margin-top: 10px;
  //this is to override the prose block component's styling
  div {
    padding: 0;
    max-width: inherit;
    ${font('body-md-medium')};
    color: ${color('fg')};
  }
`;

const Drawer = ({icon, title, content}: DrawerProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDetails = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DrawerContainer>
      <TopBar>
        {icon && <DrawerIcon backgroundImage={icon} />}
        <DrawerHint>{title}</DrawerHint>
        <DrawerToggle pointUp={isOpen} onClick={toggleDetails}>
          <CircleArrow arrowColor="#01617a" circleColor="transparent" size={28} />
        </DrawerToggle>
      </TopBar>
      <DrawerDetail isOpen={isOpen}>
        <DrawerInsert>{content}</DrawerInsert>
      </DrawerDetail>
    </DrawerContainer>
  );
};

const DrawerCombo = ({title, copy, cta, drawers}: DrawerComboProps) => {
  return (
    <Block background='panel' narrow>
      <DrawerContent>
        <div>
          <ComboTextTitle>{title}</ComboTextTitle>
          {copy && <ComboTextCopy>{copy}</ComboTextCopy>}
        </div>
        {cta.url != '' && cta.url != null && (
          <ComboCTA>
            <Button
              {...cta}
              arrowColor={'white'}
              arrow={true}
              variant={'primaryBlue'}
            />
          </ComboCTA>
        )}
      </DrawerContent>
      <Drawers>
        {drawers &&
          drawers.map(function (drawer, index) {
            return <Drawer {...drawer} key={'drawer-' + index} />;
          })}
      </Drawers>
    </Block>
  );
};

export default DrawerCombo;
