import styled, {css} from 'styled-components';
import Block from '@components/Layout/Block';
import {color, font, fontStack, query} from '@design/templateFns';
export interface flexCollarCard {
  eyebrow: string;
  headline: string;
  image: string;
  url?: string;
}
export interface FlexCollarProps {
  cards: flexCollarCard[];
}

export const FlexCollar = ({cards}: FlexCollarProps) => {
  return (
    <Block squish background="panel">
      <StyledContainer>
        {cards.map((card, index) => (
          <StyledLink href={card.url} active={card.url} key={index}>
            <StyledIndividualContainer key={index}>
              <Image src={card.image} />
              <StyledDescriptionContainer>
                <StyledEyebrow>{card.eyebrow}</StyledEyebrow>
                <StyledHeadline>{card.headline}</StyledHeadline>
              </StyledDescriptionContainer>
            </StyledIndividualContainer>
          </StyledLink>
        ))}
      </StyledContainer>
    </Block>
  );
};

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: row;

  @media (${query.below('tablet')}) {
    flex-direction: column;
  }
`;

const StyledIndividualContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  gap: 10px;
  @media (${query.below('desktop')}) {
    gap: 1px;
  }
`;

const Image = styled.img`
  width: 62px;
  height: 62px;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${color('accent')};
  ${(props) =>
    props.active
      ? {cursor: 'pointer', pointerEvents: 'default'}
      : {cursor: 'default', pointerEvents: 'none'}};
`;

const StyledEyebrow = styled.p`
  text-transform: uppercase;
  ${font('eyebrow-sm')};
  color: ${color('cta')};
  padding: 0;
  margin: 0;
`;

const StyledHeadline = styled.h1`
  ${font('headline-xxs')};
  color: ${color('primary')};
  padding: 0;
  margin: 0;

  @media (${query.below('desktop')}) {
    ${font('headline-xxxs')};
  }
`;
