import styled from 'styled-components';
import {Button} from '@components/Button';
import {query} from '@theme/fn';
import {color, font} from '@design/templateFns';
export interface BigPictureCtaProps {
  eyebrow: string;
  header: string;
  alt_text: string;
  buttonLink: string;
  buttonText: string;
  imagePath: string;
  imageAspectRatio?: number;
}

/*** STYLING ***/
const Container = styled.div`
  position: relative;
  background-size: cover;
  height: 480px;
  width: 100%;
  background-image: url(${(props) => props.backgroundImage});
  background-position: center;

  @media screen and (${query.atLeast('tablet')}) {
    height: 500px;
    background-position: inherit;
  }

  @media screen and (${query.atLeast('desktop')}) {
    height: 680px;
  }
`;

const OverlayContainer = styled.div`
  height: 440px;
  background: rgba(1, 97, 122, 0.2);
  padding: 20px;
  display: flex;

  @media screen and (${query.atLeast('tablet')}) {
    display: inherit;
    height: 460px;
  }

  @media screen and (${query.atLeast('desktop')}) {
    height: 640px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 360px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (${query.atLeast('tablet')}) {
    width: 600px;
  }

  @media screen and (${query.atLeast('desktop')}) {
    width: 850px;
  }
`;

const Eyebrow = styled.div`
  ${font('eyebrow-sm')};
  text-align: center;
  text-transform: uppercase;
  color: ${color('bg')};
  text-shadow: 0px 4px 28px ${color('shadow')};
  margin-bottom: 20px;
  @media screen and (${query.atLeast('desktop')}) {
    font-size: 14px;
  }
`;

const Header = styled.div`
  ${font('headline-xl')};
  text-align: center;
  letter-spacing: -0.01em;
  color: ${color('bg')};
  text-shadow: 0px 4px 28px ${color('shadow')};
  margin-bottom: 20px;

  @media screen and (${query.atLeast('tablet')}) {
    font-size: 40px;
  }

  @media screen and (${query.atLeast('desktop')}) {
    margin-bottom: 47px;
    font-size: 64px;
  }
`;

export const BigPictureCta = ({
  eyebrow,
  header,
  alt_text,
  imagePath,
  buttonLink,
  buttonText,
  imageAspectRatio = 1.6,
}: BigPictureCtaProps): JSX.Element => {
  return (
    <Container backgroundImage={imagePath}>
      <OverlayContainer>
        <TextContainer>
          <Eyebrow>{eyebrow}</Eyebrow>
          <Header>{header}</Header>
          {buttonLink && buttonText && (
            <Button
              variant="primaryOrange"
              label={buttonText}
              url={buttonLink}
              arrow={true}
              arrowColor="black"
            />
          )}
        </TextContainer>
      </OverlayContainer>
    </Container>
  );
};
