import React from 'react';
import styled from 'styled-components';
import {color, fontFamily} from '@theme/fn';

interface PhoneComponentProps {
  tel: string;
  fontSize: string | number;
  fontWeight: string;
  fontColor: string;
  hoverColor: string;
}

export const Phone = ({
  tel,
  fontSize = '16px',
  fontWeight = 'regular',
  fontColor,
  hoverColor = 'navy',
}: PhoneComponentProps): JSX.Element => {
  return (
    <PhoneContainer
      fontColor={fontColor}
      hoverColor={hoverColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      href={'tel:' + tel}
    >
      {tel}
    </PhoneContainer>
  );
};

const PhoneContainer = styled.a`
  color: ${({fontColor}) => (fontColor ? fontColor : color('text'))};
  ${fontFamily('body')};
  font-size: ${(props: PhoneComponentProps) => props.fontSize};
  font-weight: ${(props: PhoneComponentProps) => props.fontWeight};
  text-decoration: none;
  transition: ease-in 0.15s;
  &:hover {
    color: ${(props: PhoneComponentProps) => props.hoverColor};
  }
  white-space: nowrap;
`;
