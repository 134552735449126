import {InteriorHero, InteriorHeroProps} from '.';
import InteriorHeroData from '@data/blocks/InteriorHeroData';

const _map = (block: InteriorHeroData) => {
  const props: InteriorHeroProps = {
    header: block.header,
    caption: block.caption,
    alt_text: block.alt_text,
    imgs: {
      desktop: block.desktopImage,
      mobile: block.desktopImage,
    },
    videoSrc: null, // TODO: fix this - probably take out video --  block.videoSrc ? mapLink(block.videoSrc.asset) || null : null,
    bodyContent: block.bodyContent,
    bottomHeader: block.bottomHeader,
    koppersFullBleed: true, // default to full bleed
    // TODO: either expose `narrow` to cms, or don't make it an option.
  };
  return <InteriorHero key={block._key} {...props} />;
};

export default _map;
