
import BigPictureCtaBlockData from '@data/blocks/BigPictureCtaBlockData';
import {BigPictureCta, BigPictureCtaProps} from '.';
import {mapLink} from '@util/mapping/index';
import getImageUrl from '@util/images';

const _map = (block: BigPictureCtaBlockData) => {
  const props:BigPictureCtaProps = {
    eyebrow: block.eyebrow,
    header: block.header,
    imagePath: getImageUrl(block.image, 'crop', 1440, 680),
    imageAspectRatio: block.imageAspectRatio,
    alt_text: block.alt_text,
    buttonText: block.buttonText,
    buttonLink: mapLink(block.buttonLink),
  };
  return <BigPictureCta key={block._key} {...props} />;
};

export default _map;