import styled from 'styled-components';
import {color, space, typography, flexbox, layout, grid} from 'styled-system'; // TODO: CRITICAL: remove styled-system calls
import Link from '@components/Link';
import {font} from '@design/templateFns';
import Image from 'next/image';
import {Button} from '@components/Button/index';
import Block from '@components/Layout/Block';
import {query} from '@theme/fn';

const Chevron = () => {
  return (
    <svg
      width="8px"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13L7 7L1 1"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export interface linkMenuLink {
  title: string;
  url: string;
  _key: string;
}

export interface LinkMenuComponentProps {
  accentElement: React.ReactNode;
  // imgUrl?: string;
  header: string;
  description: string;
  btnText?: string;
  alt_text?: string;
  btnUrl?: string;
  links: linkMenuLink[];
}

export const LinkMenu = ({
  accentElement,
  header,
  description,
  btnText,
  btnUrl,
  links,
}: LinkMenuComponentProps): JSX.Element => {
  return (
    <Block>
      <StyledBox
        color="text"
        display="grid"
        gridTemplateColumns={['100%', null, null, '35% 1fr']}
        gridGap={['25px', '35px', null, '81px']}
        overflow="visible"
      >
        <StyledImageContainer
        // gridColumn={1}
        // display="block"
        // width="100%"
        >
          {accentElement}
          {/* <img src='https://cdn.sanity.io/images/oqkrzhcj/production/f1eb11607c920cafba3fed57bcd33423aafb2bbd-388x511.png?width=380&height=500' /> */}
          {/* Well this bit below sure is malfunctioning for some reason */}
          {/* <Image src={imgUrl} width={450} height={'auto'} objectFit="cover" /> */}
        </StyledImageContainer>
        <StyledBox>
          <StyledBox maxWidth={['inherit', null, null, '660px']}>
            <StyledTopHeadline>{header}</StyledTopHeadline>
            <StyledParagraph>{description}</StyledParagraph>
            <StyledBoxEdit display={['block', null, null, 'inline-block']}>
              {links.map((x) => (
                <StyledLink
                  width={['inherit', null, null, '292px']}
                  max-width={['290px', null, null, 'inherit']}
                  display="flex"
                  alignContent="center"
                  alignItems="center"
                  mb="16px"
                  href={x.url}
                  key={x._key}
                >
                  <div>
                    <StyledHeadline
                      p="0 20px"
                      m="0"
                      maxWidth={['240px', '265px', null, null]}
                    >
                      {x.title}
                    </StyledHeadline>
                    <Chevron />
                  </div>
                </StyledLink>
              ))}
            </StyledBoxEdit>
          </StyledBox>
          {((btnText && btnUrl) || null) && (
            <Button
              arrowColor="white"
              arrow={true}
              variant="primary"
              url={btnUrl}
              label={btnText}
            />
          )}
        </StyledBox>
      </StyledBox>
    </Block>
  );
};

const StyledBox = styled.div`
  ${color}
  ${typography}
  ${flexbox}
  ${space}
  ${layout}
  ${grid}
`;

const StyledBoxEdit = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledImageContainer = styled.div`
  /* ${space}
  ${layout}
  ${grid} */
  width: 100%;
  position: relative;
  & > div {
    height: 300px;
    display: block !important;
    @media screen and (${query.atLeast('desktop')}) {
      height: 355px;
    }
    @media screen and (${query.atLeast('max')}) {
      height: 757px;
      display: inline-block;
    }
  }
`;

const StyledTopHeadline = styled.h1`
  ${font('headline-xxl')};
  padding-bottom: 28px;
`;

const StyledHeadline = styled.h1`
  ${font('headline-xxs')};
  ${space}
  ${layout}
`;

const StyledParagraph = styled.p`
  ${font('body-lg-regular')};
  margin-bottom: 56px;
  @media screen and (${query.atLeast('desktop')}) {
    max-width: 500px;
  }
`;

// const Link = ({href, className, key, ...props}) => (
//   <NextLink href={href} key={key}>
//     {/* by forwarding class name we apply style to inner <a> tag instead */}
//     <a {...{href, className, key, ...props}} />
//   </NextLink>
// );

const StyledLink = styled(Link)`
  ${font('body-sm-regular')};
  background-color: #1f513f;
  border-radius: 35px;
  color: #fff;
  ${flexbox}
  ${space}
  ${layout}
  ${grid}
  padding: 15px 0 15px 16px;
  //This code at the bottom gets rid of weird CSS Column Box Shadow bug
  display: inline-flex;
  width: 100%;
  //Styled Systems Don't Support These Stylings
  /* Column Count Bug Resolution w/ Shadow */
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
  break-inside: avoid;
  transform: translateZ(0);
  text-decoration: none;
  cursor: pointer;
  svg {
    position: absolute;
    right: 20px;
  }
  div {
    display: contents;
  }
`;
