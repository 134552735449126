import {FlexCollar, flexCollarCard} from '.';
import FlexCollarBlockData from '@data/blocks/FlexCollarBlockData';
import {mapLink} from '@util/mapping/index';

const _map = (block: FlexCollarBlockData) => {
  const cards = block.cards.map(
    (card) =>
      ({
        eyebrow: card.eyebrow,
        headline: card.headline,
        image: card.image.asset.url || null,
        url: mapLink(card.url),
      } as flexCollarCard),
  );
  return <FlexCollar {...{key: block._key, cards}} />;
};

export default _map;
