import styled from 'styled-components';
import React from 'react';
import {query} from '@theme/fn';
import {color, font} from '@design/templateFns';
import {Button} from '../Button/index';
import {CircleArrow} from '@components/Arrow';
import Block from '@components/Layout/Block';
import {
  LocationDrawerComboBlockData,
  LocationDrawerComboBlock_Drawer,
} from '@data/blocks/LocationDrawerComboBlockData';
import {mapLink} from '@util/mapping';

const Chevron = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13L7 7L1 1"
        stroke="#1B76B0"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ChevronDiv = styled.div`
  height: 12px;
  width: 7px;
  margin-left: 11px;
  display: inline-block;
`;

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (${query.atLeast('tablet')}) {
    flex-direction: row;
  }
`;

const ComboCTA = styled.div`
  margin-bottom: 40px;

  @media screen and (${query.atLeast('tablet')}) {
    margin-left: 40px;
  }
`;

const ComboTextTitle = styled.div`
  ${font('headline-xxl')};
  color: ${color('fg')};
  margin-bottom: 28px;
`;

const ComboTextCopy = styled.div`
  ${font('body-lg-regular')}
  color: ${color('fg')};
  margin-bottom: 50px;
  @media screen and (${query.atLeast('desktop')}) {
    width: 65%;
  }
`;

const Drawers = styled.div`
  overflow: visible;
`;

const DrawerContainer = styled.div`
  background: ${color('bg')};
  box-shadow: 0px 10px 25px 0px ${color('shadow')};
  border-radius: 4px;
  padding: 25px 40px;
  margin: 0 0 20px 0;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
`;

const DrawerHint = styled.div`
  ${font('headline-sm')}
  color: ${color('primary')};
  text-align: left;
  margin-right: auto;
`;

const DrawerToggle = styled.div`
  cursor: pointer;
  margin-left: 20px;
  width: 26px;
  height: 26px;
  transform: ${(props) => (props.pointUp ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transition: transform 0.5s;
`;

const DrawerDetail = styled.div`
  max-height: ${(props) => (props.isOpen ? 'auto' : '0')};
  line-height: ${(props) => (props.isOpen ? '134%' : '0%')};
  opacity: ${(props) => (props.isOpen ? '1.0' : '0.0')};
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  ${font('headline-xxs')}
`;

const LocationDetail = styled.div`
  ${font('headline-xxs')}
  margin-bottom: 31px;
`;

const LocationTitle = styled.div`
  ${font('headline-xs')}
  color: ${color('cta')};
`;

const LocationDescritption = styled.p`
  margin-top: 12px;
  ${font('body-lg-regular')}
  color: ${color('fg')};
  margin-bottom: 5px;
`;

const LocationRegion = styled.p`
  margin-top: 0;
  ${font('body-lg-regular')}
  color: ${color('fg')};
  font-style: italic;
`;

const DrawerIcon = styled.div`
  width: 58px;
  min-width: 58px;
  height: 58px;
  background-image: url(${(props) => props.backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 20px;
`;

const DrawerInsert = styled.div`
  padding-top: 31px;
  margin-top: 10px;
`;

const StyledLink = styled.a`
  text-decoration: none;

  a:visited {
    color: initial;
  }
`;

const Drawer = ({icon, division}: LocationDrawerComboBlock_Drawer) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDetails = () => {
    setIsOpen(!isOpen);
  };

  //client wanted locations sorted alphabetically
  const sortedLocationsList = (division?.locationsList || []).sort(function (
    a,
    b,
  ) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });

  return (
    <DrawerContainer>
      <TopBar>
        {icon && <DrawerIcon backgroundImage={icon} />}
        <DrawerHint>{division.title}</DrawerHint>
        <DrawerToggle pointUp={isOpen} onClick={toggleDetails}>
          <CircleArrow
            arrowColor="#01617a"
            circleColor="transparent"
            size={28}
          />
        </DrawerToggle>
      </TopBar>
      <DrawerDetail isOpen={isOpen}>
        <DrawerInsert>
          {sortedLocationsList &&
            sortedLocationsList.map(function (location, index) {
              return (
                <LocationDetail key={'location-' + index}>
                  <StyledLink
                    target="_self"
                    href={'locations/' + location.slug.current}
                  >
                    <LocationTitle>
                      {location.title}
                      <ChevronDiv>
                        <Chevron />
                      </ChevronDiv>
                    </LocationTitle>
                    <LocationDescritption>
                      {location.physicalLocation}
                    </LocationDescritption>
                    <LocationRegion>{location.region.title}</LocationRegion>
                  </StyledLink>
                </LocationDetail>
              );
            })}
        </DrawerInsert>
      </DrawerDetail>
    </DrawerContainer>
  );
};

const LocationDrawerCombo = ({
  title,
  copy,
  url,
  label,
  drawers,
}: LocationDrawerComboBlockData) => {
  const cta = {
    label,
    url: mapLink(url),
  };
  return (
    <Block background="panel" narrow>
      <DrawerContent>
        <div>
          <ComboTextTitle>{title}</ComboTextTitle>
          {copy && <ComboTextCopy>{copy}</ComboTextCopy>}
        </div>
        {cta.url ? (
          <ComboCTA>
            <Button
              {...cta}
              arrowColor={'white'}
              arrow={true}
              variant={'primaryBlue'}
            />
          </ComboCTA>
        ) : null}
      </DrawerContent>
      <Drawers>
        {drawers &&
          drawers.map((drawer) => <Drawer {...drawer} key={drawer._key} />)}
      </Drawers>
    </Block>
  );
};

export default LocationDrawerCombo;
