import styled from 'styled-components';
import {LeftArrow, RightArrow} from '../Arrow/index';
import Block from '@components/Layout/Block';
import Slider from 'react-slick';
import {useRef} from 'react';
import {useState} from 'react';
import {RightArrowNoLine} from '../Arrow/index';
import theme from '@theme';
import {query} from '@theme/fn';
import React from 'react';
import {font, color} from '@design/templateFns';

const Noop = () => null;

const CarouselContainer = styled.div`
  width: 100%;
`;
// do not show carousel controls if there are 4 slides and all are visible on desktop
const CarouselHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: center;
  .max-slides {
    display: flex;
  }
  @media screen and (min-width: 1375px) {
    .max-slides {
      display: none;
    }
  }
`;

const Cta = styled.a`
  margin-left: 20px;
  margin-top: 23px;
  text-transform: capitalize;
  text-decoration: none;
  ${font('headline-xs')}
  display: flex;
  @media screen and (max-width: 980px) {
    margin-left: 0;
  }
`;

const Title = styled.div`
  display: flex;
  text-align: left;
  color: ${({color}) => (color == 'blue' ? 'white' : 'black')};
  ${font('headline-xxl')}
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
  @media screen and (max-width: 760px) {
    font-size: 28px;
    margin-top: 12px;
  }
`;

const CtaText = styled.div`
  color: ${({color}) => (color == 'blue' ? 'white' : 'black')};
  @media screen and (max-width: 760px) {
    font-size: 16px;
    padding-top: 1px;
  }
`;

// forked from Block.tsx padding
// generate css calculate rule that uses padding to lock a virtual max width
const extraPaddingCalc = (marginPx: number) =>
  `calc(${marginPx}px + max(0px, 50% - ${theme.breakpoints.max} / 2))`;

// add back Block.tsx padding only for nav when showing cards bled right
const CarouselNav = styled.div`
  display: none;
  padding-right: ${(props) =>
    props.bleedRight ? `${theme.space.marginMobile}px` : ''};

  @media screen and (${query.atLeast('tablet')}) {
    display: flex;
    padding-right: ${(props) =>
      props.bleedRight ? `${theme.space.marginTablet}px` : ''};
  }

  @media screen and (${query.atLeast('desktop')}) {
    padding-right: ${(props) =>
      props.bleedRight ? `${extraPaddingCalc(theme.space.margin)}px` : ''};
  }
  .active-arrow {
    cursor: pointer;
    pointer-events: auto;
  }
`;

//arrow is inactive unless active
const CarouselArrow = styled.div`
  padding-right: ${({right}) => (right ? '23px' : '0')};
  padding-left: ${({right}) => (right ? '0' : '23px')};
  border-left: ${({border, color}) =>
    border ? (color == 'blue' ? 'solid 1px #fff' : 'solid 1px black') : 'none'};
  height: 24px;
  cursor: none;
  pointer-events: none;
`;

export const ListContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 16px;

  /* mobile single slide carousel */
  padding-right: 40px;

  /* larger sizes */

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
    flex-wrap: nowrap;
    z-index: 4;
  }

  .slick-slide {
    // position: relative !important;
    display: flex;
    margin-left: 12px;
    margin-right: 12px;
  }

  .slick-slide > div {
    display: flex;
    flex: 0 0 100%;
  }

  .slick-dots {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    padding-inline-start: 0;
    margin-top: 44px;
  }

  .slick-dots li button {
    display: none;
  }
`;

export interface CarouselProps {
  title: string;
  cta?: string;
  cta_text?: string;
  cards: JSX.Element[];
  background?: string;
  bleedRight?: false;
}

export const CarouselSlot = styled.span`
  position: relative !important;
`;

export const StaticCarousel = ({
  title,
  cta_text,
  cta,
  cards,
  background,
  bleedRight,
}: CarouselProps): JSX.Element => {
  const ref = useRef({
    slickNext: () => {},
    slickPrev: () => {},
  });

  const onPrev = () => {
    ref.current.slickPrev();
  };

  const onNext = () => {
    ref.current.slickNext();
  };

  const [activeSlide, setActive] = useState(0);

  const numSlides = cards.length;
  const maxSlidesToShow = 4;

  const SliderConfig = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    prevArrow: <Noop />,
    nextArrow: <Noop />,
    easing: 'ease-in-out',
    slidesToScroll: 1,
    variableWidth: true,
    mobileFirst: true,
    afterChange: current => setActive(current)
  };

  return (
    <Block
      background={background == 'blue' ? 'cta' : 'panel'}
      bleedRight={bleedRight}
    >
      <CarouselContainer>
        <CarouselHeader color={background}>
          <Title color={background}>
            <span>
            {title}
            </span>
            <Cta href={cta}>
              <CtaText color={background}>{cta_text}&nbsp;&nbsp;</CtaText>
              <RightArrowNoLine stroke={'#1B76B0'} size={8} />
            </Cta>
          </Title>

          <CarouselNav className={maxSlidesToShow == numSlides ? 'max-slides' : ''} bleedRight={bleedRight}>
            <CarouselArrow className={activeSlide > 0 ? 'active-arrow' : ''} onClick={onPrev} right={true} color={background}>
              <LeftArrow stroke={activeSlide > 0 ? 'black' : 'gray'} />
            </CarouselArrow>
            <CarouselArrow className={activeSlide + 3 == numSlides ? '' : 'active-arrow'} onClick={onNext} border={true} color={background}>
              <RightArrow stroke={activeSlide + 3 == numSlides ? 'gray' : 'black'} />
            </CarouselArrow>
          </CarouselNav>
        </CarouselHeader>
        <ListContainer ref={ref} as={Slider} {...SliderConfig}>
          {cards &&
            cards.map(function (card, index) {
              return <CarouselSlot key={index}>{card}</CarouselSlot>;
            })}
        </ListContainer>
      </CarouselContainer>
    </Block>
  );
};

export default StaticCarousel;
