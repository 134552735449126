import ShortTextAndImageBlock, {ShortTextAndImageBlockProps} from '.';
import ShortTextAndImageBlockData from '@data/blocks/ShortTextAndImageBlockData';
import {Link} from '@schema/types';
import {mapLink} from '@util/mapping/index';

const _map = (block: ShortTextAndImageBlockData) => {
  const props: ShortTextAndImageBlockProps = {
    header: block.header || null,
    bodyText: block.body,
    ctaText: block.ctaText || null,
    ctaUrl: mapLink(block.ctaLink) || null,
    alt_text: block.alt_text,
    img: block.image,
    reverse: block.reverse,
  };
  return <ShortTextAndImageBlock key={block._key} {...props} />;
};

export default _map;
