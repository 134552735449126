import {ArticleCarousel, ArticleCarouselProps, mapArticle} from '.';
import ArticleCarouselBlockData from '@data/blocks/ArticleCarouselBlockData';
import {mapLink} from '@util/mapping/index';
import {number} from 'yup';

const _map = (block: ArticleCarouselBlockData) => {
  type t = ArticleCarouselBlockData['categories'];
  const props: ArticleCarouselProps = {
    _key: block._key,
    title: block.headline,
    cta_text: block.cta_text,
    cta_link: mapLink(block.cta),
    cards:
      block.selected_articles && block.selected_articles.length > 0
        ? block.selected_articles.map((article) => mapArticle(article))
        : [],
    categories:
      block.categories && block.categories.length > 0
        ? block.categories.map(function (category: {_id: string}) {
            return category._id;
          })
        : [],
  };
  return <ArticleCarousel {...props} />;
};

export default _map;
