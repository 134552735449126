import {LinkMenu, LinkMenuComponentProps, linkMenuLink} from '.';
import LinkMenuBlockData from '@data/blocks/LinkMenuBlockData';
import getImageUrl from '@util/images';
import {mapLink} from '@util/mapping/index';
import AccentImage from './LinkMenuAccentImage';

const _map = (block: LinkMenuBlockData) => {
  const props: LinkMenuComponentProps = {
    header: block.header,
    //imgUrl: getImageUrl(block.imgUrl, 'max'),
    accentElement: (
      <AccentImage desktop={block.imgUrl} mobile={block.imgMobile} />
    ),
    description: block.description,
    btnUrl: mapLink(block.btnUrl),
    btnText: block.btnText,
    links: block.links.map(function (link) {
      return {
        title: link.title,
        url: mapLink(link.url),
      } as linkMenuLink;
    }),
  };
  return <LinkMenu key={block._key} {...props} />;
};

export default _map;
