import TextAndImageBlock, {TextAndImageBlockProps} from '.';
import TextAndImageBlockData from '@data/blocks/TextAndImageBlockData';
import {Link} from '@schema/types';
import {mapLink} from '@util/mapping/index';

const _map = (block: TextAndImageBlockData) => {
  const props: TextAndImageBlockProps = {
    swatch: block.swatch || 'default',
    header: block.header || null,
    eyebrow: block.eyebrow || null,
    bodyText: block.body,
    btnText: block.buttonText || null,
    btnUrl: mapLink(block.buttonLink) || null,
    alt_text: block.alt_text,
    img: block.image,
    reverse: block.reverse,
    portrait: block.fit == 'portrait',
  };
  return <TextAndImageBlock key={block._key} {...props} />;
};

export default _map;
