import ProseBlockData from '../../data/blocks/ProseBlockData';
import Block from '../Layout/Block';
import {RenderProseContent} from '@components/PortableText';
import styled, {ThemeProvider} from 'styled-components';
import {font, color} from '@design/templateFns';
import theme, {Theme} from '@design/theme';

const Wrapper = styled.div`
  background-color: ${color('bg')};
  color: ${color('fg')};
  padding: 0;
  margin: 0;
  position: relative;
  left: 0;
  top: 0;
  ${font('body-lg-regular')};
  ${(props) => (props.centered ? 'text-align: center;' : 'text-align: left;')}
`;

const blueTheme: Theme = {
  ...theme,
  colors: {
    ...theme.colors,
    link: theme.brand.colors.ivory,
    bg: theme.brand.colors.blue,
    fg: theme.brand.colors.white,
  },
};

const ProseBlock = ({block}: {block: ProseBlockData}) => {
  const blockTheme = block.variant === 'blue' ? blueTheme : theme;
  return (
    <ThemeProvider theme={blockTheme}>
      <Block background="bg" squish>
        <Wrapper {...block} centered={block?.centered}>
          <RenderProseContent content={block?.content} />
        </Wrapper>
      </Block>
    </ThemeProvider>
  );
};

export default ProseBlock;

export const _map = (block: ProseBlockData) => (
  <ProseBlock key={block._key} block={block} />
);
