import {CardCarousel, CardCarouselProps} from '.';
import CardCarouselBlockData from '@data/blocks/CardCarouselBlockData';
import {CarouselCardProps} from '@components/Card';
import getImageUrl from '@util/images';
import {mapLink} from '@util/mapping/index';

const _map = (block: CardCarouselBlockData) => {
  const props: CardCarouselProps = {
    title: block.title,
    cta: mapLink(block.cta),
    cta_text: block.cta_text,
    swatch: block.swatch || 'ivory',
    cards: block.cards.map(function (card) {
      return {
        cardTitle: card.card_title,
        cardDescription: card.card_description || '',
        cardImage: card.card_image,
        ctaLink: mapLink(card.cta),
      } as CarouselCardProps;
    }),
  };
  return <CardCarousel key={block._key} {...props} />;
};

export default _map;
