import styled from 'styled-components';
import {Phone} from '@components/PhoneComponent';
import {Button} from '@components/Button';
import {color, font, space, query} from '@design/templateFns';

export interface CalloutBandProps {
  header: string;
  description: string;
  btnText: string;
  btnUrl: string;
  phoneNumber?: string;
  swatch: string;
}
import Block from '@components/Layout/Block';

export const CalloutBand = ({
  header = `Start the Admissions Process`,
  description = `From first contact to admissions consultation, let’s find out if we’re a good fit. `,
  btnText = `Contact Us`,
  btnUrl = `#`,
  phoneNumber,
  swatch,
}: CalloutBandProps): JSX.Element => {
  return (
    <Block breadcrumbs>
      <StyledContainer swatch={swatch}>
        <StyledSecondaryContainer>
          <StyledHeadline>{header}</StyledHeadline>
          <StyledDescriptionWrapper>
            <StyledDescription>{description}</StyledDescription>
          </StyledDescriptionWrapper>
        </StyledSecondaryContainer>
        <StyledBoxEdit>
          <Button
            variant={swatch == 'green'  || swatch == 'blue' ? 'primaryOrange' : (swatch == 'ivory' ? 'primaryBlue' : 'primaryGreen' )}
            label={btnText}
            url={btnUrl}
            arrow={true}
            arrowColor={swatch == 'ivory' || swatch == 'marigold' ? 'white' : 'black'}
          />
          <Phone
            tel={phoneNumber}
            fontSize={2}
            fontWeight="700"
            fontColor={swatch == 'green' || swatch == 'blue' ? 'white' : 'midnight'}
            hoverColor="black"
          />
        </StyledBoxEdit>
      </StyledContainer>
    </Block>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${({swatch}) =>
  swatch === 'green' || swatch === 'blue' ? color('bg') : (swatch === 'ivory' || swatch === 'marigold' ? color('midnight') : color('bg'))};
  background-color: ${({swatch}) =>
  swatch === 'green' ? color('secondaryBg') : (swatch === 'ivory' ? color('ivory') : (swatch === 'blue' ? color('blue') : color('marigold')))};
  padding: ${space('paddingVertical')} ${space('padding')};
  border-radius: 4px;
  gap: 30px;
  /* @media (${query.below('desktop')}) {
    padding: ${space('paddingMobile')};
  } */

  @media (${query.below('tablet')}) {
    flex-direction: column;
    padding: ${space('paddingMobile')};
  }
`;

const StyledSecondaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (${query.below('tablet')}) {
    max-width: 200px;
  }

  @media (${query.below('tablet')}) {
    gap: 20px;
    max-width: 200px;
    max-width: none;
  }
`;

const StyledBoxEdit = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
`;

const StyledDescriptionWrapper = styled.div`
  width: 85%;
  @media (${query.below('tablet')}) {
    width: 100%;
  }
`;

const StyledHeadline = styled.h1`
  ${font('headline-xxl')}
  padding: 0;
  margin: 0;
  @media (${query.below('desktop')}) {
    ${font('headline-md')}
  }
`;

const StyledDescription = styled.h2`
  ${font('body-md-medium')}
  padding: 0;
  margin-top: 0;

  @media (${query.below('desktop')}) {
    ${font(
      'body-sm-medium',
    )}///////////////// TODO: check this against specs ~awt
  }
`;
